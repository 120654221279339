import { useState } from "react";

const ContactUs = () => {
    const [contactInfo, setContactInfo] = useState({
      "name": '',
      'company': '',
      'phone': '',
      'email': '',
      'usecase': 'Commercial',
      'details': ''
    })

    const updateInfo = (ind, val) => {
      const newdata = {
        "name": contactInfo.name,
        'company': contactInfo.company,
        'phone': contactInfo.phone,
        'email': contactInfo.email,
        'usecase': contactInfo.usecase,
        'details': contactInfo.details,
        [ind]: val
      }
      setContactInfo(newdata)
    }

    const cancelRequest = () => {
      setContactInfo({
        "name": '',
        'company': '',
        'phone': '',
        'email': '',
        'usecase': 'Commercial',
        'details': ''
      })
    }

    return (
        <>
              <section className="skin_main">
    <div className="skin_width skin_main_bg"> 
      <section className="skin_banner">
        <div className="skin_banner_bg">
          <div id="dnn_BannerTopPane" className="bannerTopPane DNNEmptyPane"></div>
          <div id="dnn_BannerPane" className="bannerpane DNNEmptyPane"></div>
        </div>
      </section>
      <section className="skin_content">
        <div className="row dnnpane">
          <div id="dnn_ContentPane" className="content_grid12 col-md-12"><div className="DnnModule DnnModule-DNN_HTML DnnModule-437"><a name="437"></a>

<div className="Bt1_style">
  <div className="Bt1_top clearafter">
    <div className="c_icon">
      
    </div>
    <h2 className="c_title">
      <span id="dnn_ctr437_dnnTITLE_titleLabel" className="c_title_black">Contact Us</span>



    </h2>
  </div>
  <div className="c_content_style">
    <div id="dnn_ctr437_ContentPane" className="Bt1_content"><div id="dnn_ctr437_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
	<div id="dnn_ctr437_HtmlModule_lblContent" className="Normal">
	
</div>

</div></div>
  </div>
</div></div></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_OneGrid4" className="one_grid4 col-md-4 DNNEmptyPane"></div>
          <div id="dnn_OneGrid8" className="one_grid8 col-md-8 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_TwoGrid6A" className="two_grid6a col-md-6 DNNEmptyPane"></div>
          <div id="dnn_TwoGrid6B" className="two_grid6b col-md-6 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_ThreeGrid4A" className="three_grid4a col-md-4 DNNEmptyPane"></div>
          <div id="dnn_ThreeGrid4B" className="three_grid4b col-md-4 DNNEmptyPane"></div>
          <div id="dnn_ThreeGrid4C" className="three_grid4c col-md-4 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_FourGrid3A" className="four_grid3a col-md-3 DNNEmptyPane"></div>
          <div id="dnn_FourGrid3B" className="four_grid3b col-md-3 DNNEmptyPane"></div>
          <div id="dnn_FourGrid3C" className="four_grid3c col-md-3 DNNEmptyPane"></div>
          <div id="dnn_FourGrid3D" className="four_grid3d col-md-3 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_FiveGrid12" className="five_grid12 col-md-12 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_SixGrid8" className="six_grid8 col-md-8"><div className="DnnModule DnnModule-DNN_UserDefinedTable DnnModule-444"><a name="444"></a>

<div className="ch2_style">
  <div className="ch2_top clearafter">
    <div className="c_icon">
      
    </div>
    <h2 className="c_title">
      <span id="dnn_ctr444_dnnTITLE_titleLabel" className="c_title_white">Contact us</span>



    </h2>
  </div>
  <div className="ch2_shadow_l">
    <div className="ch2_shadow_r">
	  <div className="ch2_shadow_c"></div>
	</div>
  </div>
  <div className="c_content_style">
    <div id="dnn_ctr444_ContentPane" className="ch2_content"><div id="dnn_ctr444_ModuleContent" className="DNNModuleContent ModDNNUserDefinedTableC">
	<div id="dnn_ctr444_Default_PlaceHolderControl" className="dnnFormAndList"><div id="dnn_ctr444_Default_Form_divForm" className="dnnForm fnlForm dnnClear">

  <div id="dnn_ctr444_Default_Form_EditFormPlaceholder">
      <div className="dnnFormItem">
        <div className="dnnFormLabelWithoutHelp">
          <label htmlFor="dnn_ctr444_Default_Form_Name">
            <span>Name</span>
          </label>
        </div>
        <input name="dnn$ctr444$Default$Form$Name" className="dnnFormRquired" value={contactInfo.name} onChange={(e) => updateInfo('name', e.currentTarget.value)} type="text" id="dnn_ctr444_Default_Form_Name" style={{width: '100%'}} />
        </div>
      <div className="dnnFormItem">
        <div className="dnnFormLabelWithoutHelp">
          <label htmlFor="dnn_ctr444_Default_Form_Company">
            <span>Company</span>
          </label>
        </div>
        <input name="dnn$ctr444$Default$Form$Company" value={contactInfo.company} onChange={(e) => updateInfo('company', e.currentTarget.value)} type="text" id="dnn_ctr444_Default_Form_Company" style={{width: '100%'}} />
      </div>
      <div className="dnnFormItem">
        <div className="dnnFormLabelWithoutHelp">
          <label htmlFor="dnn_ctr444_Default_Form_Phone_number">
            <span>Phone number</span>
          </label>
        </div>
        <input name="dnn$ctr444$Default$Form$Phone_number" value={contactInfo.phone} onChange={(e) => updateInfo('phone', e.currentTarget.value)} type="text" id="dnn_ctr444_Default_Form_Phone_number" style={{width: '100%'}} />
      </div>
        <div className="dnnFormItem">
          <div className="dnnFormLabelWithoutHelp">
            <label htmlFor="dnn_ctr444_Default_Form_E-mail_address">
              <span>E-mail address</span>
            </label>
          </div>
          <input name="dnn$ctr444$Default$Form$E-mail_address" value={contactInfo.email} onChange={(e) => updateInfo('email', e.currentTarget.value)} type="text" id="dnn_ctr444_Default_Form_E-mail_address" className="dnnFormRequired" style={{width: '100%'}} />
        </div>
        <div className="dnnFormItem">
          <div className="dnnFormLabelWithoutHelp">
            <label htmlFor="dnn_ctr444_Default_Form_Intended_use">
              <span>Intended use</span>
            </label>
          </div>
          <select name="dnn$ctr444$Default$Form$Intended_use" value={contactInfo.usecase} onChange={(e) => updateInfo('usecase', e.currentTarget.value)} id="dnn_ctr444_Default_Form_Intended_use" style={{width: 'calc(100% + 17.6px)'}}>
            <option value="Commercial" className="dnnFormRadioButtons">Commercial</option>
            <option value="Residential" className="dnnFormRadioButtons">Residential</option>
          </select>
        </div>
        <div className="dnnFormItem">
          <div className="dnnFormLabelWithoutHelp">
            <label htmlFor="dnn_ctr444_Default_Form_Details_about_this_request">
              <span>Details about this request</span>
            </label>
          </div>
          <textarea name="dnn$ctr444$Default$Form$Details_about_this_request" value={contactInfo.details} onChange={(e) => updateInfo('details', e.currentTarget.value)} rows="7" cols="20" id="dnn_ctr444_Default_Form_Details_about_this_request" style={{width: '100%'}}></textarea>
        </div>
    </div>
    <div className="dnnActions dnnClear" style={{display: 'flex'}}>
      <a href={`mailto:kevin@gemcoroofingsupply.com?subject=${contactInfo.name}%20-%20Gemco%20Online%20Inquery&body=?Name:%20${contactInfo.name}%0ACompany:%20${contactInfo.company}%0APhone%20Number:%20${contactInfo.phone}%0AReturn%20Email:%20${contactInfo.email}%0AIntended%20Use:%20${contactInfo.usecase}%0A%0ARequest%20Details:%20${contactInfo.details}`}><button style={{margin: '0 0 0 6px'}} title="Submit" className="dnnPrimaryAction">Submit</button></a>
      <button style={{margin: '0 0 0 6px'}} title="Submit" className="dnnSecondaryAction" onClick={() => cancelRequest()}>Cancel</button>
    </div>
</div>
 <div id="dnn_ctr444_Default_Form_MessagePlaceholder"></div>
</div>

</div></div>
  </div>
</div>
</div><div className="DnnModule DnnModule-DNN_IFrame DnnModule-438"><a name="438"></a>

<div className="ch2_style">
  <div className="ch2_top clearafter">
    <div className="c_icon">
      
    </div>
    <h2 className="c_title">
      <span id="dnn_ctr438_dnnTITLE_titleLabel" className="c_title_white">Map / Driving Directions</span>



    </h2>
  </div>
  <div className="ch2_shadow_l">
    <div className="ch2_shadow_r">
	  <div className="ch2_shadow_c"></div>
	</div>
  </div>
  <div className="c_content_style">
    <div id="dnn_ctr438_ContentPane" className="ch2_content"><div id="dnn_ctr438_ModuleContent" className="DNNModuleContent ModDNNIFrameC">
	<iframe id="dnn_ctr438_IFrame_htmIFrame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2970.882440630629!2d-87.86477389999996!3d41.8738757!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4a9a0e8e97c3%3A0x31ab739c0db10433!2s2601+Van+Buren+St%2C+Bellwood%2C+IL+60104!5e0!3m2!1sen!2sus!4v1424788767804" scrolling="no" width="100%" frameBorder="0" height="400" hideadminborder="False">Your browser does not support inline frames</iframe>

</div></div>
  </div>
</div>
</div></div>
          <div id="dnn_SixGrid4" className="six_grid4 col-md-4"><div className="DnnModule DnnModule-DNN_HTML DnnModule-434"><a name="434"></a>

<div className="ch2_style">
  <div className="ch2_top clearafter">
    <div className="c_icon">
      
    </div>
    <h2 className="c_title">
      <span id="dnn_ctr434_dnnTITLE_titleLabel" className="c_title_white">Locations</span>



    </h2>
  </div>
  <div className="ch2_shadow_l">
    <div className="ch2_shadow_r">
	  <div className="ch2_shadow_c"></div>
	</div>
  </div>
  <div className="c_content_style">
    <div id="dnn_ctr434_ContentPane" className="ch2_content"><div id="dnn_ctr434_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
	<div id="dnn_ctr434_HtmlModule_lblContent" className="Normal">
	 <p><span style={{fontSize: '16px'}}>Gemco Roofing &amp; Building Supply, Inc.<br />
	 2601 Van Buren Street<br />
	 Bellwood, Illinois 60104</span><br />
	 Hours:<br /> Mon-Fri 6:30am-4:30pm <br /> Sat 6:30am-10:30am
	 &nbsp;</p>
        </div>
    </div>
</div>
</div>

  <div className="c_content_style">
    <div id="dnn_ctr434_ContentPane" className="ch2_content"><div id="dnn_ctr434_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
	<div id="dnn_ctr434_HtmlModule_lblContent" className="Normal">
	 <p><span style={{fontSize: '16px'}}>Gemco Roofing &amp; Building Supply, Inc.<br />
	 111 North River Road<br />
	 North Aurora, IL 60542</span><br />
	 Hours:<br /> Mon-Fri 7am-3:30pm
	 &nbsp;</p>
        </div>
    </div>
</div>
</div>

</div>
</div><div className="DnnModule DnnModule-DNN_HTML DnnModule-435"><a name="435"></a>

<div className="ch2_style">
  <div className="ch2_top clearafter">
    <div className="c_icon">
      
    </div>
    <h2 className="c_title">
      <span id="dnn_ctr435_dnnTITLE_titleLabel" className="c_title_white">Phone</span>



    </h2>
  </div>
  <div className="ch2_shadow_l">
    <div className="ch2_shadow_r">
	  <div className="ch2_shadow_c"></div>
	</div>
  </div>
  <div className="c_content_style">
    <div id="dnn_ctr435_ContentPane" className="ch2_content"><div id="dnn_ctr435_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
	<div id="dnn_ctr435_HtmlModule_lblContent" className="Normal">
	<p><span style={{fontSize: '16px'}}>(708) 544-1444 Office<br />
(708) 544-1621 Fax</span></p>

</div>

</div></div>
  </div>
</div>
</div></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_SevenGrid3A" className="seven_grid3a col-md-3 DNNEmptyPane"></div>
          <div id="dnn_SevenGrid3B" className="seven_grid3b col-md-3 DNNEmptyPane"></div>
          <div id="dnn_SevenGrid3C" className="seven_grid3c col-md-3 DNNEmptyPane"></div>
          <div id="dnn_SevenGrid3D" className="seven_grid3d col-md-3 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_EightGrid4A" className="eight_grid4a col-md-4 DNNEmptyPane"></div>
          <div id="dnn_EightGrid4B" className="eight_grid4b col-md-4 DNNEmptyPane"></div>
          <div id="dnn_EightGrid4C" className="eight_grid4c col-md-4 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_NineGrid6A" className="nine_grid6a col-md-6 DNNEmptyPane"></div>
          <div id="dnn_NineGrid6B" className="nine_grid6b col-md-6 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_TenGrid12" className="ten_grid12 col-md-12 DNNEmptyPane"></div>
        </div>
      </section>
      <section className="content_graybg">
        <div className="skin_main_padding">
          <div className="row dnnpane">
            <div id="dnn_GrayGrid12" className="graygrid12 col-sm-12 DNNEmptyPane"></div>
          </div>
        </div>
      </section>
    </div>
  </section>
        </>
    )
}

export default ContactUs;