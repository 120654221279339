const SpecialtyProducts = () => {
    return (
        <>
            <div id="dnn_SocialPane" className="socialpane DNNEmptyPane"></div>
            <section className="skin_main">
                <div className="skin_width skin_main_bg"> 
                <section className="skin_banner">
                    <div className="skin_banner_bg">
                    <div id="dnn_BannerTopPane" className="bannerTopPane DNNEmptyPane"></div>
                    <div id="dnn_BannerPane" className="bannerpane DNNEmptyPane"></div>
                    </div>
                </section>
                <section className="skin_content">
                    <div className="row dnnpane">
                    <div id="dnn_ContentPane" className="content_grid12 col-md-12 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_OneGrid4" className="one_grid4 col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_OneGrid8" className="one_grid8 col-md-8 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_TwoGrid6A" className="two_grid6a col-md-6 DNNEmptyPane"></div>
                    <div id="dnn_TwoGrid6B" className="two_grid6b col-md-6 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_ThreeGrid4A" className="three_grid4a col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_ThreeGrid4B" className="three_grid4b col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_ThreeGrid4C" className="three_grid4c col-md-4 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_FourGrid3A" className="four_grid3a col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_FourGrid3B" className="four_grid3b col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_FourGrid3C" className="four_grid3c col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_FourGrid3D" className="four_grid3d col-md-3 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_FiveGrid12" className="five_grid12 col-md-12 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane sponsor-format-grid">
                    <div id="dnn_SixGrid8" className="six_grid8 col-md-8"><div className="DnnModule DnnModule-DNN_HTML DnnModule-443"><a name="443"></a>

            <div className="Bt1_style">
            <div className="Bt1_top clearafter">
                <div className="c_icon">
                
                </div>
                <h2 className="c_title">
                <span id="dnn_ctr443_dnnTITLE_titleLabel" className="c_title_black">Specialty Products</span>



                </h2>
            </div>
            <div className="c_content_style">
                <div id="dnn_ctr443_ContentPane" className="Bt1_content"><div id="dnn_ctr443_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
                <div id="dnn_ctr443_HtmlModule_lblContent" className="Normal">
                <p><span style={{fontSize: '20px'}}><span style={{fontFamily: 'arial'}}>The quality provided by GEMCO continues with their line of specialty products. Other leading roofing companies pair with GEMCO to build the custom roof of your dreams! Roof hatches, decking, pavers and skylights are just some of the phenomenal specialty products that GEMCO specializes in!</span></span></p>

            <div className="content-section"><a href="http://www.davinciroofscapes.com/" target="_blank"><img alt="" src="/images/logo-DaVinciRoofscapes.png" style={{width: '217px', height: '116px'}} /></a><br />
            <a href="http://www.ecostarllc.com/" target="_blank"><img alt="" src="/images/ecostar-Logo.png" style={{width: '219px', height: '78px'}} /></a></div>

            <div className="content-section">
            <ul>
            <li><a href="http://www.davinciroofscapes.com/">Davinci Roofscapes</a></li>
            <li><a href="http://www.ecostarllc.com/">Ecostar</a></li>
            <li>Cedar Shakes</li>
            <li>Residential and Commercial Skylights</li>
            <li>Roof Hatches</li>
            <li><a href="http://www.cordeck.com">Cordeck</a></li>
            <li>Roof Pavers</li>
            </ul>
            </div>

            <div className="clearfix">
            <p>&nbsp;</p>

            <p><span style={{fontSize: '20px'}}><span style={{fontFamily: 'arial'}}>GEMCO&rsquo;S warehouse with up-to-date inventory can make your personal roofing plans come to life! For inquiries on specialty products call 708-544-1444</span></span></p>
            </div>

            </div>

            </div></div>
            </div>
            </div></div></div>
                    <div id="dnn_SixGrid4" className="six_grid4 col-md-4"><div className="DnnModule DnnModule-DNN_HTML DnnModule-430"><a name="430"></a>
            <div className="notitle_container"><div id="dnn_ctr430_ContentPane"><div id="dnn_ctr430_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
                <div id="dnn_ctr430_HtmlModule_lblContent" className="Normal">
                <p style={{textAlign: 'center'}}><strong><span style={{fontSize: '16px'}}>Gemco Roofing &amp; Building Supply is proud to be a Member of</span></strong></p>

            <p style={{textAlign: 'center'}}><img alt="" src="/images/download.png" style={{width: '211px', height: '122px', marginLeft: '50px'}} /></p>

            <p style={{textAlign: 'center'}}><span style={{fontSize: '12px'}}><em></em></span></p>

            </div>

            </div></div></div></div></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_SevenGrid3A" className="seven_grid3a col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_SevenGrid3B" className="seven_grid3b col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_SevenGrid3C" className="seven_grid3c col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_SevenGrid3D" className="seven_grid3d col-md-3 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_EightGrid4A" className="eight_grid4a col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_EightGrid4B" className="eight_grid4b col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_EightGrid4C" className="eight_grid4c col-md-4 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_NineGrid6A" className="nine_grid6a col-md-6 DNNEmptyPane"></div>
                    <div id="dnn_NineGrid6B" className="nine_grid6b col-md-6 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_TenGrid12" className="ten_grid12 col-md-12 DNNEmptyPane"></div>
                    </div>
                </section>
                <section className="content_graybg">
                    <div className="skin_main_padding">
                    <div className="row dnnpane">
                        <div id="dnn_GrayGrid12" className="graygrid12 col-sm-12 DNNEmptyPane"></div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
        </>
    )
}

export default SpecialtyProducts;