import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useEffect, useState } from 'react';
import NewsListings from './NewsListings';

const Home = () => {

  const animStart = (ind) => {
    var prev = [4, 0, 1, 2, 3]
    document.querySelectorAll(`.slide${ind}`).forEach((el) => {
      el?.classList?.add('caroanim')
    })
    document.querySelectorAll(`.slide${prev[ind]}`).forEach((el) => {
      el?.classList?.remove('caroanim')
    })
  }

  var currentHover = false

  const counterStartandSet = (ind) => {
    const circularProgress = document.querySelectorAll(".circular-progress");
    Array.from(circularProgress).forEach((progressBar) => { 
      progressBar.setAttribute("start-value", 0)
    })

    counterStart(ind)
  }

  const counterStart = (ind) => {
    animStart(ind)
    currentHover = false
    const circularProgress = document.querySelectorAll(".circular-progress");
  
    Array.from(circularProgress).forEach((progressBar) => {
      let startValue = Number(progressBar.getAttribute("start-value")), endValue = Number(progressBar.getAttribute("data-percentage")), speed = 70, progressColor = progressBar.getAttribute("data-progress-color");
  
      const progress = setInterval(() => {
        startValue++;
        //innerCircle.style.backgroundColor = `${progressBar.getAttribute("data-inner-circle-color")}`;
        progressBar.setAttribute("start-value", startValue)
        progressBar.style.background = `conic-gradient(${progressColor} ${startValue * 3.6}deg,${progressBar.getAttribute("data-bg-color")} 0deg)`;
        if (startValue === endValue || currentHover) {
          clearInterval(progress);
        }
      }, speed);
      
    });
  }

  const stopCounter = () => {
    currentHover = true
  }

  useEffect(() => {
    counterStartandSet(0)
  }, [])

  return (
    <>
      <section className="skin_main">
        <div className="skin_width skin_main_bg"> 
          <section className="skin_banner">
            <div className="skin_banner_bg">
              <div id="dnn_BannerTopPane" className="bannerTopPane"><div className="DnnModule DnnModule-DNN_HTML DnnModule-420"><a name="420"></a>
    <div className="notitle_container"><div id="dnn_ctr420_ContentPane"><div id="dnn_ctr420_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
      <div id="dnn_ctr420_HtmlModule_lblContent" className="Normal">
      <div className="skin_width">
    <table border="0" cellpadding="0" cellSpacing="0" width="100%">
    <tbody>
      <tr>
      <td style={{paddingBottom: '15px', paddingLeft: '15px', fontSize: '14px'}}>2601 Van Buren Street, Bellwood, IL 60104</td>
      <td style={{textAlign: 'right', paddingRight: '15px', paddingBottom: '15px', fontSize: '14px', fontWeight: 'bold'}}>
      <p style={{fontSize: '14px', marginBottom: '5px'}}>708.544.1444</p>
      <p style={{fontSize: '14px', marginBottom: '5px'}}>Hours: Mon-Fri 6:30a-4:30p</p>
      <p style={{fontSize: '14px', marginBottom: '5px'}}>Sat 6:30a-10:30a</p>
      </td>
      </tr>
    </tbody>
    <tbody>
      <tr>
      <td style={{paddingBottom: '15px', paddingLeft: '15px', fontSize: '14px'}}>111 North River Road, North Aurora, IL 60104</td>
      <td style={{textAlign: 'right', paddingRight: '15px', paddingBottom: '15px', fontSize: '14px', fontWeight: 'bold'}}>
      <p style={{fontSize: '14px', marginBottom: '5px'}}>708.544.1444</p>
      <p style={{fontSize: '14px', marginBottom: '5px'}}>Hours: Mon-Fri 7am-3:30pm</p>
      </td>
      </tr>
    </tbody>
    </table>
    </div>

    </div>

    </div></div></div></div></div>
    <div id="dnn_BannerPane" className="bannerpane"><div className="DnnModule DnnModule-DNN_HTML DnnModule-439"><a name="439"></a>

    <link href="/css/layerslider.css" type="text/css" rel="stylesheet" />

    <div onMouseOver={() => stopCounter()} onMouseLeave={() => counterStart()} className="layerslider_container">
      <div id="dnn_ctr439_ContentPane"><div id="dnn_ctr439_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
      <div id="dnn_ctr439_HtmlModule_lblContent" className="Normal" style={{height: '100%'}}>
      <div id="layerslider" style={{width: '100%'}}>
      <div style={{position: 'absolute', zIndex: '401', right: '8px', marginTop: '8px'}} className="circular-progress" data-inner-circle-color="rgba(50, 50, 50, 0.8)" start-value="0" data-percentage="100" data-progress-color="rgba(255, 255, 255, 0.6)" data-bg-color="rgba(255, 255, 255, 0)">
        <div className="inner-circle"></div>
      </div>

      <Carousel
        interval={8000}
        infiniteLoop
        autoPlay
        stopOnHover
        width={'100%'}
        className='carousel'
        onChange={(ind) => counterStartandSet(ind)}
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        axis='vertical'
      >
        <div className='caro-item' style={{display: 'flex', height: '100%'}}>
          <img loading='lazy' alt="" style={{width: '100%'}} src="/images/home-s1-res-bg.webp" />
          <img loading='lazy' className='slide0 caro-titleimg' src="/images/home-s1-res-text.png" style={{width: '100%', height: 'auto', position: 'absolute'}} />
          <img loading='lazy' className='slide0 caro-slideimg' src="/images/home-s1-res-l1.webp" style={{width: '100%', height: 'auto', position: 'absolute'}} />
          <img loading='lazy' className='slide0 caro-descimg' src="/images/home-s1-res-l2.png" style={{width: '100%', height: 'auto', position: 'absolute'}} />
        </div>
        
        <div className='caro-item' style={{display: 'flex', height: '100%'}}>
          <img loading='lazy' alt="" style={{width: '100%'}} src="/images/home-s2-com-bg.webp" />
          <img loading='lazy' className='slide1 caro-titleimg' src="/images/home-s2-com-text.png" style={{width: '100%', height: 'auto', position: 'absolute'}} />
          <img loading='lazy' className='slide1 caro-slideimg' src="/images/home-s2-com-l1.webp" style={{width: '100%', height: 'auto', position: 'absolute'}} />
          <img loading='lazy' className='slide1 caro-descimg' src="/images/home-s2-com-l2.png" style={{width: '100%', height: 'auto', position: 'absolute'}} />
        </div>

        <div className='caro-item' style={{display: 'flex', height: '100%'}}>
          <img loading='lazy' alt="" style={{width: '100%'}} src="/images/home-s3-inv-bg.webp" />
          <img loading='lazy' className='slide2 caro-titleimg' src="/images/home-s3-inv-text.png" style={{width: '100%', height: 'auto', position: 'absolute'}} />
          <img loading='lazy' className='slide2 caro-slideimg' src="/images/home-s3-inv-l1.webp" style={{width: '100%', height: 'auto', position: 'absolute'}} />
          <img loading='lazy' className='slide2 caro-descimg' src="/images/home-s3-inv-l2.png" style={{width: '100%', height: 'auto', position: 'absolute'}} />
        </div>

        <div className='caro-item' style={{display: 'flex', height: '100%'}}>
          <img loading='lazy' alt="" style={{width: '100%'}} src="/images/home-s4-del-bg.webp" />
          <img loading='lazy' className='slide3 caro-titleimg' src="/images/home-s4-del-text.png" style={{width: '100%', height: 'auto', position: 'absolute'}} />
          <img loading='lazy' className='slide3 caro-slideimg' src="/images/home-s4-del-l1.webp" style={{width: '100%', height: 'auto', position: 'absolute'}} />
          <img loading='lazy' className='slide3 caro-descimg' src="/images/home-s4-del-l2.png" style={{width: '100%', height: 'auto', position: 'absolute'}} />
        </div>

        <div className='caro-item' style={{display: 'flex', height: '100%'}}>
          <img loading='lazy' alt="" style={{width: '100%'}} src="/images/home-s5-kno-bg.webp" />
          <img loading='lazy' className='slide4 caro-titleimg' src="/images/home-s5-kno-text.png" style={{width: '100%', height: 'auto', position: 'absolute'}} />
          <img loading='lazy' className='slide4 caro-slideimg' src="/images/home-s5-kno-l1.webp" style={{width: '100%', height: 'auto', position: 'absolute'}} />
          <img loading='lazy' className='slide4 caro-descimg' src="/images/home-s5-kno-l2.png" style={{width: '100%', height: 'auto', position: 'absolute'}} />
        </div>
      </Carousel>
    </div>
    </div>
    </div></div>
    </div>
    </div></div>
            </div>
          </section>
          <section className="skin_content">
            <div className="row dnnpane">
              <div id="dnn_ContentPane" className="content_grid12 col-md-12 DNNEmptyPane"></div>
            </div>
            <div className="row dnnpane">
              <div id="dnn_OneGrid4" className="one_grid4 col-md-4 DNNEmptyPane"></div>
              <div id="dnn_OneGrid8" className="one_grid8 col-md-8 DNNEmptyPane"></div>
            </div>
            <div className="row dnnpane">
              <div id="dnn_TwoGrid6A" className="two_grid6a col-md-6 DNNEmptyPane"></div>
              <div id="dnn_TwoGrid6B" className="two_grid6b col-md-6 DNNEmptyPane"></div>
            </div>
            <div className="row dnnpane">
              <div id="dnn_ThreeGrid4A" className="three_grid4a col-md-4 DNNEmptyPane"></div>
              <div id="dnn_ThreeGrid4B" className="three_grid4b col-md-4 DNNEmptyPane"></div>
              <div id="dnn_ThreeGrid4C" className="three_grid4c col-md-4 DNNEmptyPane"></div>
            </div>
            <div className="row dnnpane">
              <div id="dnn_FourGrid3A" className="four_grid3a col-md-3 DNNEmptyPane"></div>
              <div id="dnn_FourGrid3B" className="four_grid3b col-md-3 DNNEmptyPane"></div>
              <div id="dnn_FourGrid3C" className="four_grid3c col-md-3 DNNEmptyPane"></div>
              <div id="dnn_FourGrid3D" className="four_grid3d col-md-3 DNNEmptyPane"></div>
            </div>
            <div className="row dnnpane">
              <div id="dnn_FiveGrid12" className="five_grid12 col-md-12 DNNEmptyPane"></div>
            </div>
            <div className="row dnnpane home-grid">
              <div id="dnn_SixGrid8" className="six_grid8 col-md-8"><div className="DnnModule DnnModule-DNN_HTML DnnModule-441"><a name="441"></a>
    <div className="notitle_container"><div id="dnn_ctr441_ContentPane"><div id="dnn_ctr441_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
      <div id="dnn_ctr441_HtmlModule_lblContent" className="Normal">
      <div className="animation fadeInUp" style={{padding: '10px 40px 10px 40px', backgroundColor: 'rgb(239, 239, 239)'}}>
    <div className="centerHeading1">About GEMCO</div>
    <h3 style={{fontFamily: 'arial'}}>Welcome to Gemco Roofing & Building Supply!</h3>
    <p className="force">At Gemco Roofing & Building Supply, with locations in Bellwood, IL and North Aurora, IL, we're your premier destinations for all your roofing and building needs. With a commitment to quality and service, we aim to be your trusted partners in every project, whether you're in the Chicagoland area or beyond.</p>    
    <h3 style={{fontFamily: 'arial'}}>Who We Are:</h3>
    <p className="force">Gemco Roofing & Building Supply isn't just a supplier; we're a team of dedicated professionals with years of experience in the industry. Our passion for excellence drives us to provide top-notch products and exceptional customer service at both our Bellwood and North Aurora locations.</p>
    <h3 style={{fontFamily: 'arial'}}>Our Journey:</h3>
    <p className="force">Gemco Roofing & Building Supply has been serving our communities since 1977. Over the years, we've built a reputation for reliability and integrity, earning the trust of contractors, builders, and homeowners throughout the Chicagoland area and beyond.</p>
    <h3 style={{fontFamily: 'arial'}}>Why Choose Us:</h3>
    <p className="force">When you choose Gemco Roofing & Building Supply, you're choosing quality you can count on. From our extensive selection of roofing materials and building supplies to our knowledgeable staff ready to assist you at both our Bellwood and North Aurora locations, we're committed to your success.</p>
    <h3 style={{fontFamily: 'arial'}}>Get in Touch:</h3>
    <p className="force">
	Ready to start your next project? Visit us at either of our convenient locations:
	<ul>
	    <li>Bellwood, IL: 2601 Van Buren Street, Bellwood, IL 60104</li>
	    <li>North Aurora, IL: 111 North River Road, North Aurora, IL 60542</li>
	</ul>
You can also connect with us on Instagram and Facebook to stay updated on the latest news and promotions.
Join us as we build together with Gemco Roofing & Building Supply!
    </p>
</div>

    </div>

    </div></div></div></div></div>
              
    <div id="dnn_SixGrid4" className="six_grid4 col-md-4 news-section">          
      <div className="DnnModule DnnModule-DNN_Announcements DnnModule-433">
        <a name="433"></a>

        <div className="ch2_style">
          <div className="ch2_top clearafter">
            <div className="c_icon"></div>
            <h2 className="c_title">
              <span id="dnn_ctr433_dnnTITLE_titleLabel" className="c_title_white">News</span>
            </h2>
          </div>
          <div className="ch2_shadow_l">
            <div className="ch2_shadow_r">
              <div className="ch2_shadow_c"></div>
            </div>
          </div>
          <NewsListings/>
        </div>
      </div>
    </div>
    </div>
            <div className="row dnnpane">
              <div id="dnn_SevenGrid3A" className="seven_grid3a col-md-3 DNNEmptyPane"></div>
              <div id="dnn_SevenGrid3B" className="seven_grid3b col-md-3 DNNEmptyPane"></div>
              <div id="dnn_SevenGrid3C" className="seven_grid3c col-md-3 DNNEmptyPane"></div>
              <div id="dnn_SevenGrid3D" className="seven_grid3d col-md-3 DNNEmptyPane"></div>
            </div>
            <div className="row dnnpane">
              <div id="dnn_EightGrid4A" className="eight_grid4a col-md-4 DNNEmptyPane"></div>
              <div id="dnn_EightGrid4B" className="eight_grid4b col-md-4 DNNEmptyPane"></div>
              <div id="dnn_EightGrid4C" className="eight_grid4c col-md-4 DNNEmptyPane"></div>
            </div>
            <div className="row dnnpane">
              <div id="dnn_NineGrid6A" className="nine_grid6a col-md-6 DNNEmptyPane"></div>
              <div id="dnn_NineGrid6B" className="nine_grid6b col-md-6 DNNEmptyPane"></div>
            </div>
            <div className="row dnnpane">
              <div id="dnn_TenGrid12" className="ten_grid12 col-md-12 DNNEmptyPane"></div>
            </div>
          </section>
          <section className="content_graybg">
            <div className="skin_main_padding">
              <div className="row dnnpane">
                <div id="dnn_GrayGrid12" className="graygrid12 col-sm-12 DNNEmptyPane"></div>
              </div>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}

export default Home;
