const Commerical = () => {

    var theForm = document.forms['Form'];
    if (!theForm) {
        theForm = document.Form;
    }
    function __doPostBack(eventTarget, eventArgument) {
        if (!theForm.onsubmit || (theForm.onsubmit() != false)) {
            theForm.__EVENTTARGET.value = eventTarget;
            theForm.__EVENTARGUMENT.value = eventArgument;
            theForm.submit();
        }
    }

    return (
        <>
<html lang="en-US">
<head id="Head">
    <meta content="text/html; charset=UTF-8" http-equiv="Content-Type" />
    <meta name="REVISIT-AFTER" content="1 DAYS" />
    <meta name="RATING" content="GENERAL" />
    <meta name="RESOURCE-TYPE" content="DOCUMENT" />
    <meta content="text/javascript" http-equiv="Content-Script-Type" />
    <meta content="text/css" http-equiv="Content-Style-Type" />
    <title>
        Gemco Roofing & Supply - Commercial Products
    </title>
    <meta id="MetaKeywords" name="KEYWORDS" content=",DotNetNuke,DNN" />
    <meta id="MetaGenerator" name="GENERATOR" content="DotNetNuke " />
    <meta id="MetaRobots" name="ROBOTS" content="INDEX, FOLLOW" />
    <link href="/css/default.css?cdv=115" media="all" type="text/css" rel="stylesheet"/>
    <link href="/css/SearchSkinObjectPreview.css?cdv=115" media="all" type="text/css" rel="stylesheet"/>
    <link href="/css/skin.css?cdv=115" media="all" type="text/css" rel="stylesheet"/>
    <link href="/css/container.css?cdv=115" media="all" type="text/css" rel="stylesheet"/>
    <link href="/css/portal.css?cdv=115" media="all" type="text/css" rel="stylesheet"/>
    <script src="/js/jquery.js?cdv=115" type="text/javascript"></script>
    <script src="/js/jquery-migrate.js?cdv=115" type="text/javascript"></script>
    <script src="/js/jquery-ui.js?cdv=115" type="text/javascript"></script>
    <link id="IE8Minus" rel="stylesheet" type="text/css" href="/css/ie8style.css" />
    <link rel='SHORTCUT ICON' href='/images/favicon.ico' type='image/x-icon' />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
</head>

<body id="Body">
<form method="post" action="/Commercial" id="Form" enctype="multipart/form-data">

<div id="skin_wrapper">
<div id="dnn_SocialPane" className="socialpane DNNEmptyPane"></div>
  <section className="skin_main">
    <div className="skin_width skin_main_bg"> 
      <section className="skin_banner">
        <div className="skin_banner_bg">
          <div id="dnn_BannerTopPane" className="bannerTopPane DNNEmptyPane"></div>
          <div id="dnn_BannerPane" className="bannerpane DNNEmptyPane"></div>
        </div>
      </section>
      <section className="skin_content">
        <div className="row dnnpane">
          <div id="dnn_ContentPane" className="content_grid12 col-md-12 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_OneGrid4" className="one_grid4 col-md-4 DNNEmptyPane"></div>
          <div id="dnn_OneGrid8" className="one_grid8 col-md-8 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_TwoGrid6A" className="two_grid6a col-md-6 DNNEmptyPane"></div>
          <div id="dnn_TwoGrid6B" className="two_grid6b col-md-6 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_ThreeGrid4A" className="three_grid4a col-md-4 DNNEmptyPane"></div>
          <div id="dnn_ThreeGrid4B" className="three_grid4b col-md-4 DNNEmptyPane"></div>
          <div id="dnn_ThreeGrid4C" className="three_grid4c col-md-4 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_FourGrid3A" className="four_grid3a col-md-3 DNNEmptyPane"></div>
          <div id="dnn_FourGrid3B" className="four_grid3b col-md-3 DNNEmptyPane"></div>
          <div id="dnn_FourGrid3C" className="four_grid3c col-md-3 DNNEmptyPane"></div>
          <div id="dnn_FourGrid3D" className="four_grid3d col-md-3 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_FiveGrid12" className="five_grid12 col-md-12 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane sponsor-format-grid">
          <div id="dnn_SixGrid8" className="six_grid8 col-md-8"><div className="DnnModule DnnModule-DNN_HTML DnnModule-431"><a name="431"></a>

<div className="Bt1_style">
  <div className="Bt1_top clearafter">
    <div className="c_icon">
    </div>
    <h2 className="c_title">
      <span id="dnn_ctr431_dnnTITLE_titleLabel" className="c_title_black">Commercial Products</span>
    </h2>
  </div>
  <div className="c_content_style">
    <div id="dnn_ctr431_ContentPane" className="Bt1_content"><div id="dnn_ctr431_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
	    <div id="dnn_ctr431_HtmlModule_lblContent" className="Normal">
	        <p>
                <span style={{fontSize: '20px'}}>
                    <span style={{fontFamily: 'arial'}}>New technology and up-to-date equipment launches GEMCO a step above the competition.&nbsp; GEMCO distributes products from top tier companies known for energy efficiency and cost effectiveness to construct your perfect commercial roof.</span></span></p>
                    <div style={{clear: 'both'}}>&nbsp;</div>
                    <div className="logobox"><span className="wrapper"><a href="http://firestonebpco.com/roofing/" target="_blank"><img alt="Firestone Building Products" src="/images/Firestone Elevate Logo.png" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="http://www.gaf.com/" target="_blank"><img alt="GAF" src="/images/logo-GAF-main.jpg" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="https://www.versico.com/" target="_blank"><img alt="Versico Roofing Systems" src="/images/logo_versico.jpg" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="http://www.certainteed.com/" target="_blank"><img alt="CertainTeed" src="/images/CT_Blue-Grey_237x52_Default.gif" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="http://www.polyglass.com/" target="_blank"><img alt="POLYGLASS" src="/images/polyglass-logo.png" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="http://www.jm.com/" target="_blank"><img alt="Johns Manville" src="/images/logo-johnsmanville-blue.png" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="http://soprema.us/" target="_blank"><img alt="Soprema" src="/images/soprema-logo.png" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="http://www.durapax.com/" target="_blank"><img alt="Durapax Coal Tar Commercial Roofing Systems" src="/images/logo-durapax_lg.jpg" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="http://www.karnakcorp.com/" target="_blank"><img alt="Karnak Corporation" src="/images/logo-karnak.png" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="http://www.rmlucas.com/" target="_blank"><img alt="R.M. Lucas Co." src="/images/logo-rmlucas.png" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="http://www.apoc.com/" target="_blank"><img alt="APOC" src="/images/apoc-logo.gif" /></a></span></div>
                    <div className="logobox"><span className="wrapper"><a href="http://www.hpanels.com/" target="_blank"><img alt="Hunter" className="full" src="/images/logo-hunter.png" /></a></span></div>
		    <div className="logobox"><span className="wrapper"><a href="http://www.gaco.com/" target="_blank"><img alt="Gaco logo" className="full" src="/images/gaco_logo.jpg" /></a></span></div>
                    <div style={{clear: 'both'}}>&nbsp;</div>
                    <p><span style={{fontSize: '20px'}}><span style={{fontFamily: 'arial'}}>Not sure what roofing solution is best for you? Call us! 708-544-1444</span></span></p>
                </div>
            </div></div>
        </div>
    </div></div></div>
          <div id="dnn_SixGrid4" className="six_grid4 col-md-4"><div className="DnnModule DnnModule-DNN_HTML DnnModule-430"><a name="430"></a>
<div className="notitle_container"><div id="dnn_ctr430_ContentPane"><div id="dnn_ctr430_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
	<div id="dnn_ctr430_HtmlModule_lblContent" className="Normal">
	<p style={{textAlign: 'center'}}>
        <strong>
            <span style={{fontSize: '16px'}}>Gemco Roofing &amp; Building Supply is proud to be a Member of</span>
        </strong>
    </p>

<p style={{textAlign: 'center'}}><img alt="" src="/images/download.png" style={{width: '211px', height: '122px', marginLeft: '50px'}} /></p>

<p style={{textAlign: 'center'}}><span style={{fontSize: '12px'}}><em></em></span></p>

</div>

</div></div></div></div></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_SevenGrid3A" className="seven_grid3a col-md-3 DNNEmptyPane"></div>
          <div id="dnn_SevenGrid3B" className="seven_grid3b col-md-3 DNNEmptyPane"></div>
          <div id="dnn_SevenGrid3C" className="seven_grid3c col-md-3 DNNEmptyPane"></div>
          <div id="dnn_SevenGrid3D" className="seven_grid3d col-md-3 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_EightGrid4A" className="eight_grid4a col-md-4 DNNEmptyPane"></div>
          <div id="dnn_EightGrid4B" className="eight_grid4b col-md-4 DNNEmptyPane"></div>
          <div id="dnn_EightGrid4C" className="eight_grid4c col-md-4 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_NineGrid6A" className="nine_grid6a col-md-6 DNNEmptyPane"></div>
          <div id="dnn_NineGrid6B" className="nine_grid6b col-md-6 DNNEmptyPane"></div>
        </div>
        <div className="row dnnpane">
          <div id="dnn_TenGrid12" className="ten_grid12 col-md-12 DNNEmptyPane"></div>
        </div>
      </section>
      <section className="content_graybg">
        <div className="skin_main_padding">
          <div className="row dnnpane">
            <div id="dnn_GrayGrid12" className="graygrid12 col-sm-12 DNNEmptyPane"></div>
          </div>
        </div>
      </section>
    </div>
  </section>
 </div>
<script type="text/javascript" src="/js/initWidgets.js" ></script>
</form>
</body>
</html>
        </>
    )
}

export default Commerical;