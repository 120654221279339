const Footer = () => {
    return (
        <>
            <div id="Footer4">
                <div className="skin_width">
                    <div className="skin_footer_bg"> 
                    <section className="footer_top">
                        <div id="footerPane_position" className="footerpane_style skin_main_padding"> </div>
                    </section>
                    <footer className="footer_wrap clearafter">
                        <div className="copyright_style">
                        <span id="dnn_Footer4_dnnCOPYRIGHT_lblCopyright" className="Footer">Copyright 2024 GEMCO Roofing & Building Supply, Inc.</span>

                        </div>
                        <div className="footer_right">
                        <a id="dnn_Footer4_dnnPRIVACY_hypPrivacy" className="Footer" rel="nofollow" href="http://gemcoroofingsupply.com/Privacy">Privacy Statement</a>
                        |
                        <a id="dnn_Footer4_dnnTERMS_hypTerms" className="Footer" rel="nofollow" href="http://gemcoroofingsupply.com/Terms">Terms Of Use</a>
                        </div>
                    </footer>
                    </div>
                </div>
            </div>

            <section id="footerPanes">
                <div className="row dnnpane">
                <div id="dnn_FooterGrid3A" className="footer_grid3a col-md-3 DNNEmptyPane"></div>
                <div id="dnn_FooterGrid3B" className="footer_grid3b col-md-3 DNNEmptyPane"></div>
                <div id="dnn_FooterGrid3C" className="footer_grid3c col-md-3 DNNEmptyPane"></div>
                <div id="dnn_FooterGrid3D" className="footer_grid3d col-md-3 DNNEmptyPane"></div>
                </div>
                <div className="row dnnpane">
                <div id="dnn_FooterGrid8A" className="footer_grid8a col-md-8 DNNEmptyPane"></div>
                <div id="dnn_FooterGrid4B" className="footer_grid4b col-md-4 DNNEmptyPane"></div>
                </div>
                <div className="row dnnpane">
                <div id="dnn_FooterGrid12" className="footer_grid12 col-md-12 DNNEmptyPane"></div>
                </div>
            </section>
            <a href="#top" id="top-link" title="Top"> </a>

            <input name="ScrollTop" type="hidden" id="ScrollTop" />
            <input name="__dnnVariable" type="hidden" id="__dnnVariable" autoComplete="off" value="`{`__scdoff`:`1`,`sf_siteRoot`:`/`,`sf_tabId`:`93`}" />
            <input name="__RequestVerificationToken" type="hidden" value="rUCwox3t-dSvMpwOZGJA_AWJiVBA0Oqm6OpAUfhhsG7n6-SQ9eChnnSyM_68XowO_DPkpuEu13mvVN7jjJBQzl4he7La2cbH4rfRc94wZ3-daGjjEDBLIwNr-VY1" />
        </>
    )
}

export default Footer;