import { useEffect } from "react"

const Header = () => {

    const toggleMenu = () => {
        document.getElementById('menu_style').classList.toggle('menu_open')
        document.getElementById('menu_style').classList.toggle('menu_close') //primary animation driven classes
    }

    useEffect(() => {
        const pathname = window.location.pathname;
        document?.getElementById(`${pathname.substring(1)}`)?.classList?.add('selected')
    }, [])

    return (
        <>
            <div className="aspNetHidden">
                <input type="hidden" name="StylesheetManager_TSSM" id="StylesheetManager_TSSM" value="" />
                <input type="hidden" name="ScriptManager_TSM" id="ScriptManager_TSM" value="" />
                <input type="hidden" name="__EVENTTARGET" id="__EVENTTARGET" value="" />
                <input type="hidden" name="__EVENTARGUMENT" id="__EVENTARGUMENT" value="" />
                <input type="hidden" name="__VIEWSTATE" id="__VIEWSTATE" value="fv28d7LkIFDN4KAchV+OskdOMDQvPorIZume9DFWPbI8coltMEw07x+615OGzfdefwocbVqMTOvmWRe8ZiAjngUqQvw=" />
            </div>

            <div className="aspNetHidden">
                <input type="hidden" name="__VIEWSTATEGENERATOR" id="__VIEWSTATEGENERATOR" value="CA0B0334" />
                <input type="hidden" name="__VIEWSTATEENCRYPTED" id="__VIEWSTATEENCRYPTED" value="" />
                <input type="hidden" name="__EVENTVALIDATION" id="__EVENTVALIDATION" value="peKlYzqiOAQyp/AE+p6Xh/Qwy9YV5DjTl2c2RJnBbbjvBviCodtb0HzNlc/GI+u+SRnGUpKWuuSBVp16QUR30R40i27QDY1mTCjR7Sg9lWJIlcE8TJEWYc1HCb7lZlzSZYYlEPAkQtnlnkepZYohAGRdmGxdaWNWqitFpY8A5It7v6VmW8c7sPK3DZs=" />
            </div>
            <div id="ControlPanelWrapper"></div>
            <div>
                <link id="headerCSS" rel="stylesheet" href="/css/Header2.css"></link>
                <header id="HeaderTwo">
                    <div className="skin_width">
                        <section className="skin_user clearafter">
                        <div className="skin_top_right">
                            <div className="user_style">
                            <div className="language-object" ></div>
                            </div>
                        </div>
                        <div id="skin_social"> </div>
                        </section>
                        <section className="header_style clearafter">
                        <div className="logo_style">
                            <div className="site_logo">
                            <a id="dnn_Header2_dnnLOGO_hypLogo" title="GEMCO Roofing &amp; Building Supply, Inc." href="/"><img style={{paddingLeft: '10px'}} loading="lazy" id="dnn_Header2_dnnLOGO_imgLogo" src="/images/gemco_logo_mask_200.png" alt="GEMCO Roofing &amp; Building Supply, Inc." /></a>
                            </div>
                            <div className="mobile_nav" onClick={() => toggleMenu()} ><a href="#" className="menuclick" style={{background: 'url(./images/btn_menu.png) no-repeat center center'}}><img loading="lazy" alt="Menu" className="click_img" src="/images/blank.gif" /></a></div>
                        </div>
                        <div className="skin_search"> <a className="search_button" title="Open Search"><span></span></a> <a className="search_button_close" title="Close Search"><span></span></a>
                            <div id="Search">
                                <div className="search_position">
                                    <span id="dnn_Header2_dnnSEARCH_ClassicSearch">
                                        <span className="searchInputContainer" data-moreresults="See More Results" data-noresult="No Results Found">
                                            <input name="dnn$Header2$dnnSEARCH$txtSearch" type="text" maxLength="255" size="20" id="dnn_Header2_dnnSEARCH_txtSearch" className="NormalTextBox" autoComplete="off" placeholder="Search..." />
                                            <a className="dnnSearchBoxClearText"></a>
                                        </span>
                                        <a id="dnn_Header2_dnnSEARCH_cmdSearch" className="searchcss" href="/home">Search</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <nav className="menu_style menu_close" id="menu_style">
                            <div id="standardMenu">
                                <ul className="rootMenu">
                                    <li id="Home" className="item first child-1"><a href="/Home" target=""><span>Home</span></a><div className="setHover"></div>
                                    <div className="setHover02"></div>
                                    </li>
                                    <li id="Residential" className="item child-2"><a href="/Residential" target=""><span>Residential</span></a><div className="setHover"></div>
                                    <div className="setHover02"></div>
                                    </li>
                                    <li id="Commercial" className="item child-3"><a href="/Commercial" target=""><span>Commercial</span></a><div className="setHover"></div>
                                    <div className="setHover02"></div>
                                    </li>
                                    <li id="Specialty-Products" className="item child-4"><a href="/Specialty-Products" target=""><span>Specialty Products</span></a><div className="setHover"></div>
                                    <div className="setHover02"></div>
                                    </li>
                                    <li id="News" className="item child-5"><a href="/News" target=""><span>News</span></a><div className="setHover"></div>
                                    <div className="setHover02"></div>
                                    </li>
                                    <li id="Contact-Us" className="item last child-6"><a href="/Contact-Us" target=""><span>Contact Us</span></a><div className="setHover"></div>
                                    <div className="setHover02"></div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                        </section>
                    </div>
                </header>
            </div>
        </>
    )
}

export default Header;