const Residential = () => {
    return (
        <>
            <div id="dnn_SocialPane" className="socialpane DNNEmptyPane"></div>
            <section className="skin_main">
                <div className="skin_width skin_main_bg"> 
                <section className="skin_banner">
                    <div className="skin_banner_bg">
                    <div id="dnn_BannerTopPane" className="bannerTopPane DNNEmptyPane"></div>
                    <div id="dnn_BannerPane" className="bannerpane DNNEmptyPane"></div>
                    </div>
                </section>
                <section className="skin_content">
                    <div className="row dnnpane">
                    <div id="dnn_ContentPane" className="content_grid12 col-md-12 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_OneGrid4" className="one_grid4 col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_OneGrid8" className="one_grid8 col-md-8 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_TwoGrid6A" className="two_grid6a col-md-6 DNNEmptyPane"></div>
                    <div id="dnn_TwoGrid6B" className="two_grid6b col-md-6 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_ThreeGrid4A" className="three_grid4a col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_ThreeGrid4B" className="three_grid4b col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_ThreeGrid4C" className="three_grid4c col-md-4 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_FourGrid3A" className="four_grid3a col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_FourGrid3B" className="four_grid3b col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_FourGrid3C" className="four_grid3c col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_FourGrid3D" className="four_grid3d col-md-3 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_FiveGrid12" className="five_grid12 col-md-12 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane sponsor-format-grid">
                    <div id="dnn_SixGrid8" className="six_grid8 col-md-8"><div className="DnnModule DnnModule-DNN_HTML DnnModule-427"><a name="427"></a>

            <div className="Bt1_style">
            <div className="Bt1_top clearafter">
                <div className="c_icon">
                
                </div>
                <h2 className="c_title">
                <span id="dnn_ctr427_dnnTITLE_titleLabel" className="c_title_black">Residential Products</span>



                </h2>
            </div>
            <div className="c_content_style">
                <div id="dnn_ctr427_ContentPane" className="Bt1_content"><div id="dnn_ctr427_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
                <div id="dnn_ctr427_HtmlModule_lblContent" className="Normal">
                <p><span style={{fontSize: '20px'}}><span style={{fontFamily: 'arial'}}>Carrying a complete line of supplies, tools and accessories, GEMCO is the solution for your residential roofing needs. &nbsp;Using top of the line &nbsp;products, roofing has never been so easy! Let GEMCO, Chicagoland&rsquo;s leading roofing supply distributor, provide your complete roofing solution.</span></span></p>

            <div style={{clear: 'both'}}>&nbsp;</div>

            <div className="logobox"><span className="wrapper"><a href="http://www.certainteed.com/" target="_blank"><img alt="CertainTeed" src="/images/CT_Blue-Grey_237x52_Default.gif" /></a></span></div>

            <div className="logobox"><span className="wrapper"><a href="http://www.gaf.com/" target="_blank"><img alt="GAF" src="/images/logo-GAF-main.jpg" /></a></span></div>

            <div style={{clear: 'both'}}>&nbsp;</div>

            <p><span style={{fontSize: '20px'}}><span style={{fontFamily: 'arial'}}>Not sure what roofing solution is best for you? Call us! 708-544-1444</span></span></p>

            </div>

            </div></div>
            </div>
            </div></div></div>
                    <div id="dnn_SixGrid4" className="six_grid4 col-md-4"><div className="DnnModule DnnModule-DNN_HTML DnnModule-430"><a name="430"></a>
            <div className="notitle_container"><div id="dnn_ctr430_ContentPane"><div id="dnn_ctr430_ModuleContent" className="DNNModuleContent ModDNNHTMLC">
                <div id="dnn_ctr430_HtmlModule_lblContent" className="Normal">
                <p style={{textAlign: 'center'}}><strong><span style={{fontSize: '16px'}}>Gemco Roofing &amp; Building Supply is proud to be a Member of</span></strong></p>

            <p style={{textAlign: 'center'}}><img alt="" src="/images/download.png" style={{width: '211px', height: '122px', marginLeft: '50px'}} /></p>

            <p style={{textAlign: 'center'}}><span style={{fontSize: '12px'}}><em></em></span></p>

            </div>

            </div></div></div></div></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_SevenGrid3A" className="seven_grid3a col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_SevenGrid3B" className="seven_grid3b col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_SevenGrid3C" className="seven_grid3c col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_SevenGrid3D" className="seven_grid3d col-md-3 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_EightGrid4A" className="eight_grid4a col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_EightGrid4B" className="eight_grid4b col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_EightGrid4C" className="eight_grid4c col-md-4 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_NineGrid6A" className="nine_grid6a col-md-6 DNNEmptyPane"></div>
                    <div id="dnn_NineGrid6B" className="nine_grid6b col-md-6 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_TenGrid12" className="ten_grid12 col-md-12 DNNEmptyPane"></div>
                    </div>
                </section>
                <section className="content_graybg">
                    <div className="skin_main_padding">
                    <div className="row dnnpane">
                        <div id="dnn_GrayGrid12" className="graygrid12 col-sm-12 DNNEmptyPane"></div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
        </>
    )
}

export default Residential;