import NewsListings from "./NewsListings";

const News = () => {
    return (
        <>
            <div id="dnn_SocialPane" className="socialpane DNNEmptyPane"></div>
            <section className="skin_main">
                <div className="skin_width skin_main_bg"> 
                <section className="skin_banner">
                    <div className="skin_banner_bg">
                    <div id="dnn_BannerTopPane" className="bannerTopPane DNNEmptyPane"></div>
                    <div id="dnn_BannerPane" className="bannerpane DNNEmptyPane"></div>
                    </div>
                </section>
                <section className="skin_content">
                    <div className="row dnnpane">
                    <div id="dnn_ContentPane" className="content_grid12 col-md-12 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_OneGrid4" className="one_grid4 col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_OneGrid8" className="one_grid8 col-md-8 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_TwoGrid6A" className="two_grid6a col-md-6 DNNEmptyPane"></div>
                    <div id="dnn_TwoGrid6B" className="two_grid6b col-md-6 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_ThreeGrid4A" className="three_grid4a col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_ThreeGrid4B" className="three_grid4b col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_ThreeGrid4C" className="three_grid4c col-md-4 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_FourGrid3A" className="four_grid3a col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_FourGrid3B" className="four_grid3b col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_FourGrid3C" className="four_grid3c col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_FourGrid3D" className="four_grid3d col-md-3 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_FiveGrid12" className="five_grid12 col-md-12 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_SixGrid8" className="six_grid8 col-md-8"><div className="DnnModule DnnModule-DNN_Announcements DnnModule-433"><a name="433"></a>

            <div className="Bt1_style">
            <div className="Bt1_top clearafter">
                <div className="c_icon">
                
                </div>
                <h2 className="c_title">
                    <span id="dnn_ctr433_dnnTITLE_titleLabel" className="c_title_black">News</span>
                </h2>
            </div>

            <NewsListings/>

            </div></div></div>
                    <div id="dnn_SixGrid4" className="six_grid4 col-md-4 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_SevenGrid3A" className="seven_grid3a col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_SevenGrid3B" className="seven_grid3b col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_SevenGrid3C" className="seven_grid3c col-md-3 DNNEmptyPane"></div>
                    <div id="dnn_SevenGrid3D" className="seven_grid3d col-md-3 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_EightGrid4A" className="eight_grid4a col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_EightGrid4B" className="eight_grid4b col-md-4 DNNEmptyPane"></div>
                    <div id="dnn_EightGrid4C" className="eight_grid4c col-md-4 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_NineGrid6A" className="nine_grid6a col-md-6 DNNEmptyPane"></div>
                    <div id="dnn_NineGrid6B" className="nine_grid6b col-md-6 DNNEmptyPane"></div>
                    </div>
                    <div className="row dnnpane">
                    <div id="dnn_TenGrid12" className="ten_grid12 col-md-12 DNNEmptyPane"></div>
                    </div>
                </section>
                <section className="content_graybg">
                    <div className="skin_main_padding">
                    <div className="row dnnpane">
                        <div id="dnn_GrayGrid12" className="graygrid12 col-sm-12 DNNEmptyPane"></div>
                    </div>
                    </div>
                </section>
                </div>
            </section>
        </>
    )
}

export default News;