import news from '../json/news.json';

const NewsListings = () => {

    console.log(news)

    return (
        <>
            {news.listings.map((el) => {
               return (
                <div className="c_content_style">
                    <div id="dnn_ctr433_ContentPane" className="Bt1_content">
                        <div id="dnn_ctr433_ModuleContent" className="DNNModuleContent ModDNNAnnouncementsC">
                            <table className="DNN_ANN_DesignTable" cellSpacing="0" summary="Announcements Design Table" border="0" style={{borderCollapse: 'collapse'}}>
                                <tr>
                                    <td>
                                        <table>
                                            <tr>
                                                <td>

                                                </td>
                                                <td valign="top">
                                                    <span className="SubHead">{`${el.title}`}</span>
                                                    <div className="Normal DNN_ANN_Description"><p><img alt={`${el.alttx}`} src={`/images/${el.image}`} style={{width: '100%', maxWidth: '400px'}} />{`${el.descr}`}</p>&nbsp;</div>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <hr style={{border: 'lightgray solid 1px'}}/>
                </div>
               ) 
            })}
        </>
    )
}

export default NewsListings