import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import React from 'react';

import SpecialtyProducts from './components/SpecialtyProducts';
import Residential from './components/Residential';
import Commerical from './components/Commercial';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import Header from './components/Header';
import News from './components/News';
import Home from './components/Home';

import './css/SearchSkinObjectPreview.css?cdv=115';
import './css/skin.css?cdv=115';
import './css/container.css?cdv=115';
import './css/portal.css?cdv=115';

import './css/Footer4.css';
import './css/CustomStyle.css';
/* import './css/bootstrap.css'; */
import './css/Header2.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <div id='skin_wrapper'>
    <Header/>
    <Router>
      <Routes>
        <Route path='/Specialty-Products' element={<SpecialtyProducts/>}/>
        <Route path='/Residential' element={<Residential/>}/>
        <Route path='/Commercial' element={<Commerical/>}/>
        <Route path='/Contact-Us' element={<ContactUs/>}/>
        <Route path='/index' element={<Home/>}/>
        <Route path='/News' element={<News/>}/>
        <Route path='/Home' element={<Home/>}/>
        <Route path='/' element={<Home/>}/>
      </Routes>
    </Router>
    <Footer/>
  </div>
);